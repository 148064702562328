<!-- {{ data | json}} -->
<div class="landing-carosal-wrapper carousel-wrapper h-100">
  <owl-carousel-o class="h-100" [options]="customOptions">
    <ng-template *ngFor="let slide of data" carouselSlide>
      <div *ngIf="(slide.caption || slide.caption2) && (slide.svg || slide.img)" class="slide d-flex">
        <div class="captions-container">
          <h2
            *ngIf="slide.caption"
            class="caption text-white"
            typingEffect
            [textToType]="slide.caption"
            [typingSpeed]="100"
            [startDelay]="0"
          ></h2>
          <h2
          *ngIf="slide.caption2"
          class="caption text-white"
          typingEffect
          [textToType]="slide.caption2"
          [typingSpeed]="100"
          [startDelay]="2000"
          ></h2>
        </div>
        <div
          *ngIf="slide.svg"
          class="svg-container"
          [replaceSvg]="slide.svg"
        ></div>
        <img
          *ngIf="slide.img"
          src="../../../../../assets/images/{{ slide.img }}"
          alt=""
          class="slide-img"
        />
      </div>
      <div *ngIf="slide.img && !(slide.caption || slide.caption2 || slide.svg)" class="image-container">
        <img src="{{ slide.img }}" alt="" class="single-image" />
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
