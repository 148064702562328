import { Component, Input } from '@angular/core';
import { joinToday } from '../../constants/svgs/join-today';
import { joinEngage } from '../../constants/svgs/join-engage';
import { joinHlthera } from '../../constants/svgs/join-hlthera';
import { Router } from '@angular/router';

@Component({
  selector: 'hlthera-cloud-card',
  templateUrl: './cloud-card.component.html',
  styleUrl: './cloud-card.component.scss',
})
export class CloudCardComponent {
  @Input() headerTitle: string = 'Join';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() subLabel: string = '';
  @Input() buttonLable: string = 'Get Started';
  @Input() showLogo: boolean = true;
  @Input() showButton: boolean = true;
  @Input() logoTitle: string = '';
  @Input() buttonLink: string = '/stay-tuned';
  @Input() hyparLink: string = '';
  @Input() svg!: any;
  icon = {
    today: 'today',
    engage: 'engage',
    hlthera: 'hlthera',
  };
  iconToday = joinToday;
  iconEngage = joinEngage;
  iconHlthera = joinHlthera;
  constructor(readonly router: Router) {}

  onButtonClick() {
    if (this.hyparLink) this.openLink();
    else this.router.navigate([this.buttonLink]);
  }

  openLink() {
    window.open(this.hyparLink, '_blank');
  }
}
