import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' }, // Redirect root to /home
  {
    path: '',
    loadChildren: () =>
      import('./modules/content/content.module').then((m) => m.ContentModule),
  },
  {
    path: 'engage',
    loadChildren: () =>
      import('./modules/engage/engage.module').then((m) => m.EngageModule),
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./modules/contact-us/contact-us.module').then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: 'health-centre-profile',
    loadChildren: () =>
      import('./modules/health-centre-profile/health-centre-profile.module').then(
        (m) => m.HealthCentreProfileModule
      ),
  },
  {
    path: 'healer-profile',
    loadChildren: () =>
      import('./modules/healer-profile/healer-profile.module').then(
        (m) => m.HealerProfileModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/search-results/search-results.module').then(
        (m) => m.SearchResultsModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
