// title.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITitleType } from '../interfaces/ititle-type';

@Injectable({
  providedIn: 'root',
})

export class TitleService {
  private titleSubject = new BehaviorSubject<ITitleType>({} as ITitleType);
  title$ = this.titleSubject.asObservable();

  setTitle(title: ITitleType) {
    this.titleSubject.next(title);
  }
}
