<!-- Main Container -->
<div class="hlthera-main-container">
  <hlthera-navbar></hlthera-navbar>

  <div class="main">
    <router-outlet></router-outlet>
  </div>

  <hlthera-footer></hlthera-footer>
</div>

<!-- Loading Spinner -->
<div *ngIf="isLoading" class="loading-container">
  <hlthera-loading></hlthera-loading>
</div>
