import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private exceptions: string[] = ['terms-and-conditions', 'privacy-policy'];

  constructor(private router: Router) {
    this.scrollToTopOnNavigation();
  }

  scrollToTopOnNavigation(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.shouldPreventScroll(event.url)) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    });
  }

  private shouldPreventScroll(url: string): boolean {
    return this.exceptions.some((exception) => url.includes(exception));
  }

  scrollToElement(elementId: string, offset: number = 120): void {
    const element = document.getElementById(elementId);
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }
}
