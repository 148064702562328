<div class="logo-wrapper">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="228.305"
    height="245.333"
    viewBox="605.848 495.982 228.305 245.333"
  >
    <g data-name="hlthera">
      <path
        d="M769.76 606.472v118.54h33.068V598.336c-12.816 4.5-27.242 7.16-33.068 8.135Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 111"
      />
      <path
        d="M757.333 585.436h.016l.183.006-.199-.006Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 96"
      />
      <path
        d="m759.355 582.6-.026-.006.026.006Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 97"
      />
      <g data-name="Group 45">
        <path
          d="M608.64 710.572c-2.773-8.554-3.322-17.83-2.341-26.316a58.08 58.08 0 0 1 .466-3.312c1.367-8.223 4.162-15.457 7.698-20.208a61.052 61.052 0 0 1 5.342-6.248c9.71-10.41 22.185-15.566 29.411-17.844 17.303-5.958 33.915-6.336 39.511-7.39 27.374-5.159 81.04-3.529 114.377-11.943 3.323-.838 13.822-4.614 18.159-7.87 5.8-4.358 8.435-8.952 7.81-12.905-1.365-5.82-9.18-8.095-9.18-8.095s-7.4-2.434-17.135-2.231c-9.734.202-32.854-1.116-32.854-1.116h-10.85l1.116-2.468h10.41l32.178-.609.074-.003v-.246l1.085-.01c27.58-.214 35.935 13.76 26.523 26.332-1.625 2.281-3.968 4.735-7.335 7.19-12.708 9.261-41.98 13.52-41.98 13.52l-11.22 1.692-62.463 7.537s-18.353 2.332-19.67 2.839c-1.319.506-32.651 8.316-32.651 8.316s-33.945 9.188-32.516 42.083c-.102 0-.124 20.232 15.365 27.209 8.27 3.05 16.878 1.696 16.878 1.696l2.477-.03.432-.074a.394.394 0 0 0-.01.069l19.055-.236v21.17h-19.046v.09s-1.435.154-3.824.154c-9.98 0-36.647-2.709-45.292-30.743Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Union 1"
        />
      </g>
      <g data-name="Group 42">
        <path
          d="M698.058 587.588a1.161 1.161 0 1 0 0 2.317h4.582c-.538-.762-1.018-1.555-1.498-2.317h-3.084Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 104818"
        />
        <path
          d="M727.821 573.488a1.16 1.16 0 0 1 1.188-.793 1.13 1.13 0 0 1 1.05.896l5.289 22.636 5.156-12.034 3.33-7.773c.17-.442.603-.727 1.077-.708.469-.003.893.277 1.076.708l3.255 8.12 2.999-7.076c.166-.396.536-.668.964-.708.429-.062.854.14 1.076.512l3.51 5.347h2.35c.391-.92.722-1.865.99-2.829 4.272-16.523-14.965-30.78-31.66-14.143-16.69-16.69-35.905-2.434-31.632 14.144a28.462 28.462 0 0 0 3.479 7.698h21.868l4.635-13.997Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 104819"
        />
        <path
          d="m756.114 584.562-2.829-4.035-3.282 7.62a1.16 1.16 0 0 1-2.151 0l-3.058-8.069-8.769 20.596a1.161 1.161 0 0 1-1.076.708 1.188 1.188 0 0 1-1.044-.897l-5.236-22.415-3.677 11.091c-.165.47-.605.786-1.103.794h-21.218a119.7 119.7 0 0 0 26.652 24.756s21.927-14.657 29.566-29.59h-1.897a1.214 1.214 0 0 1-.878-.56Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 104820"
        />
        <path
          d="M760.09 582.573h10.966v2.636l-12.152-.101 1.187-2.535Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 150"
        />
      </g>
      <path
        d="M759.357 582.6Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 101"
      />
      <path
        d="m757.578 585.445.133.007c.073.002.147.006.226.01l-.36-.017Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 102"
      />
      <path
        d="M759.447 582.62h-.005Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 103"
      />
      <g data-name="Path 104">
        <path
          d="M802.832 581.915v4.267a95.91 95.91 0 0 0-2.713.06c-9.941.363-21.644.07-30.36-.26v-3.551l33.073-.516Z"
          fill="#fff"
          fill-rule="evenodd"
        />
        <path
          d="M802.832 581.915v4.267a95.91 95.91 0 0 0-2.713.06c-9.941.363-21.644.07-30.36-.26v-3.551l33.073-.516Z"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke="rgba(0,0,0,0 )"
          fill="transparent"
        />
      </g>
      <path
        d="m758.448 585.484-.45-.02.45.02Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 108"
      />
      <path
        d="m757.32 585.435-.156-.004.155.004Z"
        fill="#fff"
        fill-rule="evenodd"
        data-name="Path 109"
      />
      <g data-name="Group 41">
        <g data-name="Group 39">
          <g data-name="Group 38">
            <g data-name="Group 36">
              <path
                d="M654.852 495.982H802.83v17.348H654.852v-17.348z"
                fill="#fff"
                fill-rule="evenodd"
                data-name="Rectangle 10"
              />
            </g>
            <g data-name="Group 37">
              <path
                d="M825.598 529.593H632.625v5.422h22.225v11.926h147.98v-11.926h22.768v-5.422Z"
                fill="#fff"
                fill-rule="evenodd"
                data-name="Path 105"
              />
            </g>
          </g>
        </g>
        <path
          d="M802.834 735.853v-11.927h-41.198v-74.27h-65.044v74.27H654.85v17.348h59.083v-74.27h30.358v74.27h81.306v-5.421h-22.763Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 107"
        />
        <g data-name="Group 40">
          <path
            d="M802.833 546.67h-25.675c-5.227 0-7.397 7.271-7.397 10.974v52.712c11.967-.966 23.367-2.292 33.072-4.323V546.67Z"
            fill="#fff"
            fill-rule="evenodd"
            data-name="Path 110"
          />
        </g>
        <path
          d="M687.921 546.656h-33.07V725.01h25.676c5.226 0 7.394-8.674 7.394-13.09V546.655Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="Path 106"
        />
      </g>
    </g>
  </svg>
</div>

<div id="load">
  <div>R</div>
  <div>E</div>
  <div>D</div>
  <div>E</div>
  <div>F</div>
  <div>I</div>
  <div>N</div>
  <div>E</div>
  <div>D</div>

  <!-- Space between words -->
  <div>H</div>
  <div>E</div>
  <div>A</div>
  <div>L</div>
  <div>I</div>
  <div>N</div>
  <div>G</div>

</div>
