import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

@Component({
  selector: 'hlthera-cards-scroll-list',
  templateUrl: './cards-scroll-list.component.html',
  styleUrls: ['./cards-scroll-list.component.scss'],
})
export class CardsScrollListComponent implements AfterViewInit, OnDestroy {
  @Input() cardNumbers: number = 3;
  activeCard: number = 1;
  cards: number[] = [];
  private scrollTrigger: any;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.cards = Array.from({ length: this.cardNumbers }, (_, i) => i + 1);
  }

  ngAfterViewInit() {
    const totalCards = Math.max(this.cardNumbers, 1);
    const cardsContainer = this.elementRef.nativeElement.querySelector('.cards');

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: cardsContainer,
        pin: true,
        pinSpacing: true,
        markers: false,
        start: 'top-=120px top',
        end: `+=${totalCards * 350}vh`,
        scrub: 5,
        onUpdate: (self) => this.updateActiveCard(self.progress),
      },
    });

    for (let i = 1; i <= totalCards; i++) {
      const card = cardsContainer.querySelector(`.card${i}`);
      if (i > 1) {
        const prevCard = cardsContainer.querySelector(`.card${i - 1}`);
        tl.to(
          prevCard,
          {
            scale: 0.95,
            yPercent: -10,
            opacity: 0.7,
            zIndex: 1,
            duration: 0.5,
          },
          `-=${0.5}`
        )
        .to(card, {
          yPercent: 0,
          opacity: 1,
          zIndex: 2,
          duration: 1,
        })
        .from(
          cardsContainer.querySelector(`.card${i + 1}`),
          {
            yPercent: 50,
            opacity: 0,
            zIndex: 1,
            duration: 1,
          },
          `+=0.5`
        );
      } else {
        tl.to(card, {
          yPercent: 0,
          opacity: 1,
          zIndex: 2,
          duration: 1,
        }).from(cardsContainer.querySelector(`.card${i + 1}`), {
          yPercent: 50,
          opacity: 0,
          zIndex: 1,
          duration: 1,
        });
      }
      tl.addLabel(`card${i}`);
    }

    this.scrollTrigger = tl.scrollTrigger; // Keep a reference to the scrollTrigger for cleanup
  }

  ngOnDestroy() {
    // Cleanup the scrollTrigger when the component is destroyed
    if (this.scrollTrigger) {
      this.scrollTrigger.kill(true);
    }
  }

  updateActiveCard(progress: number) {
    const totalCards = Math.max(this.cardNumbers, 1);
    const cardIndex = Math.round(progress * (totalCards - 1)) + 1;
    this.activeCard = cardIndex;
  }
}
