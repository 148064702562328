import { Component, Input } from '@angular/core';

@Component({
  selector: 'hlthera-data-loader',
  templateUrl: './data-loader.component.html',
  styleUrl: './data-loader.component.scss'
})
export class DataLoaderComponent {
  @Input() message: string = 'Loading...'

}
