import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'hlthera-pick-date-shared-carousel',
  templateUrl: './pick-date-carousel.component.html',
  styleUrl: './pick-date-carousel.component.scss'
})
export class PickDateCarouselComponent {
  @Input() availableDates!: string[]
  @Input() selectedAvailableDate: string = ''
  @Output() changeSelectedDate: EventEmitter<string> = new EventEmitter<string>();
  options: OwlOptions = {
      loop:false,
      margin: 10,
      nav: true,
      dots: false,
      autoWidth: true,
      autoHeight: false,
      navText: [`<img src="/assets/images/arrow-left-black.svg" alt="prev" />`, `<img src="/assets/images/arrow-right-black.svg" alt="next" />`],
      responsive:{
          0:{
            items: 1,
          },
          600:{
              items: 1
          },
          1000:{
              items: 3
          }
      }
    }
  ngOnInit() {

  }

  onSelectDate(date: string) {
    this.changeSelectedDate.emit(date)
  }

}
