import { Component } from '@angular/core';

@Component({
  selector: 'hlthera-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {

}
