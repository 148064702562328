import { Component, Input } from '@angular/core';

@Component({
  selector: 'hlthera-profile-tab-content-title',
  templateUrl: './profile-tab-content-title.component.html',
  styleUrl: './profile-tab-content-title.component.scss'
})
export class ProfileTabContentTitleComponent {
  @Input() title: string = '';
}
