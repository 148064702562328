<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="cards">
        <!-- Navigation with links to cards -->
        <ul class="gsb-nav">
          <li *ngFor="let card of cards; let i = index">
            <a>
              <div class="circle" [class.active]="activeCard === i + 1">
                <span class="font-regular-medium"> 0{{ i + 1 }} </span>
              </div>
            </a>
          </li>
        </ul>

        <!-- Card content -->
        <ng-content></ng-content>
        <div class="card{{ cardNumbers + 1 }}"></div>
      </div>
    </div>
  </div>
</div>
