import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScrollService } from './modules/shared/services/scroll-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Hlthera';
  isLoading: boolean = true;
  homeInint: boolean = false;

  constructor(
    private translate: TranslateService,
    private scrollService: ScrollService,
    private route: ActivatedRoute
  ) {
    // Set default language
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.checkIfHomeRoute();
    this.handleLoadingState();
    this.scrollService.scrollToTopOnNavigation();
  }

  private checkIfHomeRoute(): void {
    this.route.url.subscribe((res) => {
      this.homeInint = res.length === 0 || res[0].path === '';
    });
  }

  private handleLoadingState(): void {
    const isFirstLoad = localStorage.getItem('isFirstLoad') === null; // Check if it's the first load based on localStorage

    const timeoutDuration = isFirstLoad ? 8000 : 5000;

    setTimeout(() => {
      this.isLoading = false;

      if (isFirstLoad) {
        localStorage.setItem('isFirstLoad', 'false');
      }
    }, timeoutDuration);
  }
}
