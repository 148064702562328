<section class="cloud-card-wrapper">
  <!-- Svg Clouds -->
  <svg class="cloud1" xmlns="http://www.w3.org/2000/svg" width="156.341" height="72.659" viewBox="0 0 156.341 72.659">
    <g id="Group_116891" data-name="Group 116891" transform="translate(78.17 36.329)" opacity="0.15">
      <path id="Path_319203" data-name="Path 319203"
        d="M67.245,14.477a10.993,10.993,0,0,0-2.044.2c0-.066.005-.132.005-.2A21.85,21.85,0,0,0,38.719-6.878,36.335,36.335,0,0,0,3.048-36.329,36.332,36.332,0,0,0-32.731-6.261a21.817,21.817,0,0,0-6.89-1.115A21.852,21.852,0,0,0-61.473,14.477c0,.575.03,1.141.072,1.7a10.868,10.868,0,0,0-5.843-1.7A10.926,10.926,0,0,0-78.17,25.4,10.927,10.927,0,0,0-67.244,36.33H67.245a10.927,10.927,0,0,0,0-21.853Z"
        fill="#fff" />
    </g>
  </svg>
  <svg class="cloud2" xmlns="http://www.w3.org/2000/svg" width="61.448" height="28.557" viewBox="0 0 61.448 28.557">
    <path id="Path_319203" data-name="Path 319203"
      d="M-21.017-16.361a4.321,4.321,0,0,0-.8.078c0-.026,0-.052,0-.078a8.589,8.589,0,0,0-8.589-8.589,8.605,8.605,0,0,0-1.821.2,14.281,14.281,0,0,0-14.02-11.575A14.28,14.28,0,0,0-60.311-24.511a8.575,8.575,0,0,0-2.708-.438,8.589,8.589,0,0,0-8.589,8.589c0,.226.012.449.028.67a4.271,4.271,0,0,0-2.3-.67,4.3,4.3,0,0,0-4.294,4.294,4.3,4.3,0,0,0,4.294,4.295h52.859a4.3,4.3,0,0,0,4.294-4.295A4.294,4.294,0,0,0-21.017-16.361Z"
      transform="translate(78.17 36.329)" fill="#4f89c4" />
  </svg>
  <svg class="cloud3" xmlns="http://www.w3.org/2000/svg" width="94.894" height="44.101" viewBox="0 0 94.894 44.101">
    <path id="Path_319205" data-name="Path 319205"
      d="M10.092-5.492a6.672,6.672,0,0,0-1.241.12c0-.04,0-.08,0-.12A13.264,13.264,0,0,0-4.409-18.755a13.289,13.289,0,0,0-2.813.3A22.054,22.054,0,0,0-28.873-36.329,22.052,22.052,0,0,0-50.59-18.078a13.242,13.242,0,0,0-4.182-.677A13.264,13.264,0,0,0-68.036-5.492c0,.349.018.693.044,1.034a6.6,6.6,0,0,0-3.547-1.034A6.632,6.632,0,0,0-78.17,1.14a6.632,6.632,0,0,0,6.632,6.632h81.63a6.632,6.632,0,0,0,0-13.264Z"
      transform="translate(78.17 36.329)" fill="#4a87bd36" />
  </svg>
  <!-- Content -->
  <div class="content flex-center-all flex-column h-100">
    <div *ngIf="showLogo" class="logo d-flex text-white">
      <h1 class="join">{{headerTitle}}</h1>
      <div
        class="mx-2 cl-logo"
        *ngIf="icon.today === svg"
        [replaceSvg]="iconToday"
      ></div>
      <div
        class="mx-2 cl-logo"
        *ngIf="icon.engage === svg"
        [replaceSvg]="iconEngage"
      ></div>
      <div
        class="mx-2 cl-logo"
        *ngIf="icon.hlthera === svg"
        [replaceSvg]="iconHlthera"
      ></div>
      <h1>{{ logoTitle }}</h1>
    </div>
    <h2 *ngIf="title" class="text-white">{{ title }}</h2>
    <p class="description text-grey font-regular-large text-white">{{ description }}</p>
    <hlthera-link-button
      *ngIf="showButton"
      [label]="buttonLable"
      type="secondary"
      class="mt-4"
      (click)="onButtonClick()"
    >
    </hlthera-link-button>
    <p *ngIf="subLabel" class="text-grey font-regular-medium text-white">
      {{ subLabel }}
    </p>
  </div>


</section>
