

<div class="slider-container" *ngIf="availableDates">
  <button *ngIf="availableDates.length && availableDates.length < 2" class="nav-btn left-btn"
    disabled>
    <img src="/assets/images/arrow-left-black.svg" alt="prev" />
  </button>
  <div class="slider-wrapper">
    <div class="slider">
      <owl-carousel-o [options]="options">
        <ng-container *ngFor="let date of availableDates">
          <ng-template carouselSlide [width]="95">
            <div (click)="onSelectDate(date)" class="slider-item" [ngClass]="{'active': selectedAvailableDate === date}">
              <ng-container *ngIf="date">
                {{ date | date: 'EE, MMM d' }}
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
  <button *ngIf="availableDates.length && availableDates.length < 2" class="nav-btn right-btn"
    disabled>
    <img src="/assets/images/arrow-right-black.svg" alt="next" />
  </button>
</div>
