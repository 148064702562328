export const joinToday = `<svg xmlns="http://www.w3.org/2000/svg" width="39.044" height="43.622" viewBox="0 0 39.044 43.622">
  <g id="LOGO" transform="translate(-64.718)">
    <g id="hlthera" transform="translate(64.718)">
      <path id="Path_94" data-name="Path 94" d="M2686.077,330.078c.213-.494.314-1.446.525-1.446.278,0,.649,3.759,1.112,3.759.557,0,1.3-3.566,1.67-3.566.278,0,.37,1.542.741,1.542.278,0,.464-1.542.742-1.542.186,0,.371.867.649.867h.055l.063,0h-.023l.061,0-.038,0,.042,0h0l.08,0h.023l.2-.5h0l-.049-.009h0l-.014,0h0l-.059-.015h0a.674.674,0,0,1-.433-.349c-.186-.385-.371-.771-.557-.771-.372,0-.556,1.35-.741,1.35-.278,0-.186-1.542-.649-1.542s-1.39,3.567-1.668,3.567c-.37,0-.556-4.048-1.112-4.048-.313,0-.649,1.446-.927,2.313-.066.2-.092.289-.371.289h-3.549l.346.578h3.11C2685.952,330.56,2685.952,330.367,2686.077,330.078Z" transform="translate(-2665.66 -313.788)" fill="none"/>
      <path id="Path_111" data-name="Path 111" d="M2761.332,387.916v21.077h5.655V386.47A35.542,35.542,0,0,1,2761.332,387.916Z" transform="translate(-2733.301 -368.27)" fill="#fff"/>
      <path id="Path_96" data-name="Path 96" d="M2747.059,342.31h0Z" transform="translate(-2721.152 -326.405)" fill="#fff"/>
      <path id="Path_97" data-name="Path 97" d="M2749.361,339.05Z" transform="translate(-2723.108 -323.649)" fill="#fff"/>
      <g id="Group_45" data-name="Group 45" transform="translate(0 15.251)">
        <path id="Union_1" data-name="Union 1" d="M.477,22.9a11.627,11.627,0,0,1-.4-4.679q.032-.3.08-.589a8.747,8.747,0,0,1,1.317-3.593,10.755,10.755,0,0,1,.914-1.111A11.784,11.784,0,0,1,7.417,9.76a31.638,31.638,0,0,1,6.757-1.314c4.681-.917,13.859-.627,19.56-2.123a12.051,12.051,0,0,0,3.106-1.4c.992-.775,1.443-1.592,1.336-2.294-.234-1.035-1.57-1.439-1.57-1.439a9.366,9.366,0,0,0-2.93-.4c-1.665.036-5.618-.2-5.618-.2H26.2l.191-.439h1.78l5.5-.108h.013V0l.186,0c4.717-.038,6.145,2.447,4.536,4.682a5.581,5.581,0,0,1-1.254,1.278c-2.173,1.647-7.179,2.4-7.179,2.4l-1.919.3-10.682,1.34s-3.139.415-3.364.5-5.584,1.479-5.584,1.479-5.805,1.634-5.561,7.483c-.018,0-.021,3.6,2.628,4.838a6.232,6.232,0,0,0,2.886.3l.424-.005.074-.013a.072.072,0,0,0,0,.012l3.259-.042v3.764H8.877v.016a6.478,6.478,0,0,1-.654.028A7.659,7.659,0,0,1,.477,22.9Z" transform="translate(0 0)" fill="#fff"/>
      </g>
      <g id="Group_42" data-name="Group 42" transform="translate(15.559 11.061)">
        <path id="Path_104818" data-name="Path 104818" d="M169.266,32.38a.2.2,0,0,0-.182.1.213.213,0,0,0,0,.214.2.2,0,0,0,.182.1h.784c-.092-.136-.174-.277-.256-.412Z" transform="translate(-169.055 -27.153)" fill="#fff"/>
        <path id="Path_104819" data-name="Path 104819" d="M174.419,24.708a.2.2,0,0,1,.2-.141.2.2,0,0,1,.179.159l.9,4.025.882-2.14.57-1.382a.189.189,0,0,1,.184-.126h0a.2.2,0,0,1,.184.126l.557,1.444.513-1.258a.2.2,0,0,1,.165-.126.182.182,0,0,1,.184.091l.6.951h.4a4.175,4.175,0,0,0,.169-.5c.731-2.938-2.559-5.473-5.414-2.515-2.854-2.968-6.14-.433-5.41,2.515a5.159,5.159,0,0,0,.595,1.369h3.74Z" transform="translate(-169.118 -21.987)" fill="#fff"/>
        <path id="Path_104820" data-name="Path 104820" d="M180.25,30.164l-.484-.717L179.2,30.8a.2.2,0,0,1-.368,0l-.523-1.435-1.5,3.662a.2.2,0,0,1-.184.126h0a.206.206,0,0,1-.179-.159l-.9-3.986-.629,1.972a.2.2,0,0,1-.189.141H171.11a20.807,20.807,0,0,0,4.558,4.4s3.75-2.606,5.056-5.261H180.4a.207.207,0,0,1-.15-.1Z" transform="translate(-170.11 -25.475)" fill="#fff"/>
        <path id="Path_150" data-name="Path 150" d="M-5.047,0h1.875V.469L-5.25.451Z" transform="translate(15.867 4.336)" fill="#fff"/>
      </g>
      <path id="Path_101" data-name="Path 101" d="M2749.39,339.056Z" transform="translate(-2723.137 -323.655)" fill="#fff"/>
      <path id="Path_102" data-name="Path 102" d="M2747.348,342.321h.023l.039,0Z" transform="translate(-2721.399 -326.414)" fill="#fff"/>
      <path id="Path_103" data-name="Path 103" d="M2749.481,339.081h0Z" transform="translate(-2723.214 -323.676)" fill="#fff"/>
      <path id="Path_104" data-name="Path 104" d="M2766.984,338.27v.759q-.226,0-.464.011c-1.7.064-3.7.012-5.191-.046v-.631Z" transform="translate(-2733.297 -322.991)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="Path_108" data-name="Path 108" d="M2747.9,342.346l-.077,0Z" transform="translate(-2721.801 -326.431)" fill="#fff" opacity="0.21"/>
      <path id="Path_109" data-name="Path 109" d="M2746.9,342.306h0Z" transform="translate(-2720.994 -326.4)" fill="#fff" opacity="0.21"/>
      <g id="Group_41" data-name="Group 41" transform="translate(4.579)">
        <g id="Group_39" data-name="Group 39">
          <g id="Group_38" data-name="Group 38">
            <g id="Group_36" data-name="Group 36" transform="translate(3.801)">
              <rect id="Rectangle_10" data-name="Rectangle 10" width="25.307" height="3.085" fill="#fff"/>
            </g>
            <g id="Group_37" data-name="Group 37" transform="translate(0 5.976)">
              <path id="Path_105" data-name="Path 105" d="M2636.959,277.924h-33v.964h3.8v2.121h25.307v-2.121h3.894Z" transform="translate(-2603.957 -277.924)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_107" data-name="Path 107" d="M2654.768,431.339v-2.121h-7.045V416.012H2636.6v13.206h-7.138V432.3h10.1V419.1h5.192V432.3h13.9v-.964Z" transform="translate(-2625.66 -388.688)" fill="#fff"/>
        <g id="Group_40" data-name="Group 40" transform="translate(23.452 9.013)">
          <path id="Path_110" data-name="Path 110" d="M2766.984,297.829h-4.391c-.894,0-1.265,1.293-1.265,1.951v9.373a45.271,45.271,0,0,0,5.656-.769Z" transform="translate(-2761.328 -297.829)" fill="#fff"/>
        </g>
        <path id="Path_106" data-name="Path 106" d="M2635.116,297.829h-5.656v31.713h4.391c.894,0,1.265-1.542,1.265-2.328Z" transform="translate(-2625.66 -288.819)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

`;
