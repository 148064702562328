<header class="container" id="header">
  <nav>
    <!-- Existing header content -->
    <div class="main-logo">
      <a [routerLink]="['/']">
        <img src="/assets/images/Hlthera-logo.svg" class="logo" alt="Hlthera Home" title="Home" />
      </a>
    </div>
    <ul class="nav-bar">
      <li>
        <ng-container *ngIf="pageTitle">
          <h4 class="mx-4 text-blue">{{ pageTitle.title! }}</h4>

          <div
            class="page-logo"
            *ngIf="pageTitle?.logoType === titleLogoType.ProfessionalServices"
            [replaceSvg]="headerLogos.prof"
          ></div>
          <div
            class="page-logo"
            *ngIf="pageTitle?.logoType === titleLogoType.Engage"
            [replaceSvg]="headerLogos.engage"
          ></div>
          <div
            class="page-logo"
            *ngIf="pageTitle?.logoType === titleLogoType.DefaultLogo"
            [replaceSvg]="headerLogos.default"
          ></div>
          <div
            class="page-logo"
            *ngIf="pageTitle?.logoType === titleLogoType.Foundation"
            [replaceSvg]="headerLogos.foun"
          ></div>
        </ng-container>
      </li>
      <!-- <li>
        <div id="toggel-btn" class="togel-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </li>
      <li>
        <div id="language-switcher" class="lang-icon flex-center-all">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33.055"
            height="32.483"
            viewBox="0 0 33.055 32.483"
          >
            <path
              id="Path_319210"
              data-name="Path 319210"
              d="M3.918,20.941A16.432,16.432,0,0,0,6.6,29.956a18.426,18.426,0,0,1,5.652-4.429q.508-.257,1.026-.477c-.122-1.331-.183-2.707-.183-4.108,0-1.5.073-2.979.213-4.4a18.01,18.01,0,0,1-6.6-4.776,16.434,16.434,0,0,0-2.791,9.174Zm13.262,16.2a16.539,16.539,0,0,1-9.541-5.769,16.491,16.491,0,0,1,5.84-4.553,29.952,29.952,0,0,0,1.573,6.511,12.833,12.833,0,0,0,2.127,3.811Zm3.265.038c2.149,0,4.5-4.329,5.328-11.014a16.7,16.7,0,0,0-10.656,0C15.949,32.855,18.3,37.183,20.445,37.183Zm12.806-5.807a16.538,16.538,0,0,1-9.541,5.769,12.825,12.825,0,0,0,2.129-3.809,29.93,29.93,0,0,0,1.572-6.512A16.487,16.487,0,0,1,33.251,31.376Zm.933-19.612a18.014,18.014,0,0,1-6.6,4.777c.14,1.421.213,2.9.213,4.4,0,1.4-.063,2.777-.183,4.108q.518.223,1.027.477a18.412,18.412,0,0,1,5.65,4.43,16.494,16.494,0,0,0-.106-18.191ZM25.839,8.549A12.823,12.823,0,0,0,23.71,4.737a16.543,16.543,0,0,1,9.422,5.624,16.332,16.332,0,0,1-5.762,4.405,29.579,29.579,0,0,0-1.532-6.216ZM13.52,14.766a16.34,16.34,0,0,1-5.762-4.4A16.541,16.541,0,0,1,17.18,4.737a12.823,12.823,0,0,0-2.129,3.813,29.622,29.622,0,0,0-1.532,6.217Zm1.636.642a16.757,16.757,0,0,0,5.277.849h.012a16.762,16.762,0,0,0,5.289-.849C24.87,8.9,22.562,4.7,20.445,4.7S16.021,8.9,15.156,15.407ZM14.8,20.941c0,1.213.048,2.379.137,3.491a18.348,18.348,0,0,1,11.011,0c.089-1.112.137-2.278.137-3.491,0-1.325-.057-2.594-.162-3.8a18.5,18.5,0,0,1-5.48.824h-.013a18.523,18.523,0,0,1-5.471-.825C14.86,18.347,14.8,19.615,14.8,20.941Z"
              transform="translate(-3.918 -4.7)"
              fill="#8794aa"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </li> -->
    </ul>
  </nav>
  <div id="black-overlay" class="overlay"></div>
</header>
