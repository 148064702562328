import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Shared_Components } from './components';
import { Shared_Directives } from './directives';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DataLoaderComponent } from './components/data-loader/data-loader.component';
import { Convert24To12Pipe } from './pipes/convert-24-to-12.pipe';
const _SharedModule = [CarouselModule];
@NgModule({
  declarations: [...Shared_Components, ...Shared_Directives, CapitalizePipe, DataLoaderComponent, Convert24To12Pipe],
  imports: [..._SharedModule, CommonModule, TranslateModule],
  exports: [
    ...Shared_Components,
    ...Shared_Directives,
    ..._SharedModule,
    CapitalizePipe,
    Convert24To12Pipe
  ],
})
export class SharedModule {}
