import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TitleLogoType } from 'src/app/modules/shared/enum/title-logo-typt';
import { ITitleType } from 'src/app/modules/shared/interfaces/ititle-type';
import { TitleService } from 'src/app/modules/shared/services/title-services';
import { HeaderLogosSvg } from '../constants/svg/header-logos';

@Component({
  selector: 'hlthera-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'], // Changed from styleUrl to styleUrls
})
export class NavbarComponent implements AfterViewInit, OnDestroy, OnInit {
  pageTitle!: ITitleType;
  headerSvg = HeaderLogosSvg;
  headerLogos = {
    prof: this.headerSvg.professeional,
    engage: this.headerSvg.engage,
    default: this.headerSvg.defaultLogo,
    foun: this.headerSvg.foundation,
  };
  titleLogoType = TitleLogoType;
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.title$.subscribe((res: ITitleType) => {
      this.pageTitle = res;
    });
  }
  private header: HTMLElement | null = null;
  private sticky: number = 0;

  ngAfterViewInit(): void {
    this.header = document.getElementById('header');
    if (this.header) {
      this.sticky = this.header.offsetTop;
      window.addEventListener('scroll', this.onScroll);
    }
  }

  private onScroll = (): void => {
    if (this.header) {
      if (window.pageYOffset > this.sticky) {
        this.header.classList.add('sticky');
      } else {
        this.header.classList.remove('sticky');
      }
    }
  };

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll);
  }
}
