export const joinHlthera = `<svg xmlns="http://www.w3.org/2000/svg" width="145.898" height="35" viewBox="0 0 165.898 40.427">
  <g id="Group_117018" data-name="Group 117018" transform="translate(-638.249 -237.731)">
    <g id="Group_44" data-name="Group 44" transform="translate(741.99 257.029)">
      <path id="Path_104815" data-name="Path 104815" d="M225.422,59.559a10.122,10.122,0,0,1-2.9-7.562,10.417,10.417,0,0,1,2.923-7.661,10.062,10.062,0,0,1,7.462-2.942,8.509,8.509,0,0,1,6.058,2.331,7.633,7.633,0,0,1,2.429,5.8c0,1.133-.89,1.7-2.681,1.7H226.587A8.248,8.248,0,0,0,228.8,56.94a7.12,7.12,0,0,0,5.446,2.331,7.927,7.927,0,0,0,4.211-1.277,4.131,4.131,0,0,1,1.4-.672,1.2,1.2,0,0,1,.694.3.932.932,0,0,1,.387.739,2.674,2.674,0,0,1-1.478,2.126,10.818,10.818,0,0,1-3.264,1.445,12.3,12.3,0,0,1-3.119.466A10.467,10.467,0,0,1,225.422,59.559Zm12.122-10.538a6.233,6.233,0,0,0-1.256-3.921,4.3,4.3,0,0,0-3.546-1.506,5.726,5.726,0,0,0-4.084,1.674,5.562,5.562,0,0,0-1.865,3.837h4.9q3.268,0,5.847-.084Z" transform="translate(-222.506 -41.388)" fill="#fff"/>
      <path id="Path_104816" data-name="Path 104816" d="M274.418,62.012h-1.8c-1.832,0-2.96-.28-3.394-.839a1.436,1.436,0,0,1-.3-.933.76.76,0,0,1,.42-.746,2.253,2.253,0,0,1,.932-.228,1.7,1.7,0,0,0,.933-.327,1.119,1.119,0,0,0,.42-.975v-10.3q0-1.868-.466-2.308a2.682,2.682,0,0,0-.755-.564,9.807,9.807,0,0,0-.932-.312,7.6,7.6,0,0,1-1.059-.378.611.611,0,0,1-.41-.564q0-1.679,4.507-1.679,1.907,0,2.42.665a5.682,5.682,0,0,1,.513,3.045,5.763,5.763,0,0,1,2.35-2.825,6.28,6.28,0,0,1,3.371-1.025,5.064,5.064,0,0,1,2.849.739,2.21,2.21,0,0,1,1.124,1.865,2.36,2.36,0,0,1-.739,1.818,2.532,2.532,0,0,1-1.8.681,2.984,2.984,0,0,1-1.832-.564,2.569,2.569,0,0,1-1-1.464,6.119,6.119,0,0,0-.9-.084q-3.264,0-3.264,7.52V58.01c0,.813.466,1.24,1.352,1.287s1.352.41,1.352,1.082a1.336,1.336,0,0,1-1.256,1.4A12.966,12.966,0,0,1,274.418,62.012Z" transform="translate(-246.792 -41.561)" fill="#fff"/>
      <path id="Path_104817" data-name="Path 104817" d="M313.469,48.577a3.348,3.348,0,0,1-1.758-.62,1.838,1.838,0,0,1-.975-1.6,3.4,3.4,0,0,1,.569-1.865,4.6,4.6,0,0,1,1.469-1.436,9.121,9.121,0,0,1,2.093-.933,15.387,15.387,0,0,1,4.692-.7q4.229,0,6.15,1.865t1.916,6.188v6.2q0,2.243.564,2.8a2.835,2.835,0,0,0,.933.378c1.427.466,2.142.793,2.142,1.025q0,2.2-4.507,2.2-1.7,0-2.21-.755a5.32,5.32,0,0,1-.556-2.742,6.952,6.952,0,0,1-2.9,2.728,8.486,8.486,0,0,1-4.126,1.049,10.811,10.811,0,0,1-3.73-.566,5.376,5.376,0,0,1-2.48-1.832,4.481,4.481,0,0,1-.932-2.734,6.944,6.944,0,0,1,.331-2.331A5.224,5.224,0,0,1,311,53.4a4.709,4.709,0,0,1,1.4-1.091,11.833,11.833,0,0,1,1.625-.765,12.543,12.543,0,0,1,1.944-.513q1.762-.336,2.955-.466c.8-.093,1.207-.148,1.235-.159s.564-.065,1.436-.159a5.381,5.381,0,0,0,2.061-.555q0-3.981-1.068-5.194a3.99,3.99,0,0,0-3.1-1.045,4.341,4.341,0,0,0-3.236,1.1,4.836,4.836,0,0,0-1.077,3.488V48.5A14.137,14.137,0,0,1,313.469,48.577ZM323.6,51.551a13.742,13.742,0,0,1-3.212.723,19.993,19.993,0,0,0-2.821.466,7.158,7.158,0,0,0-1.715.672,3.348,3.348,0,0,0-1.59,3.161q0,3.6,4.2,3.6a4.329,4.329,0,0,0,4.588-3.455,19.3,19.3,0,0,0,.555-5.172Z" transform="translate(-269.109 -41.405)" fill="#fff"/>
    </g>
    <g id="Group_43" data-name="Group 43" transform="translate(638.249 246.41)">
      <path id="Path_104812" data-name="Path 104812" d="M28.822,51.562H26.606c-2.024,0-3.264-.3-3.73-.9a1.45,1.45,0,0,1-.317-.933.739.739,0,0,1,.387-.723,3.138,3.138,0,0,1,1.031-.284,3.134,3.134,0,0,0,.89-.21,1.478,1.478,0,0,0,.779-1.454V37.695H7.705v9.364a1.562,1.562,0,0,0,.494,1.31,2.262,2.262,0,0,0,1.2.466,5.829,5.829,0,0,1,.9.135.714.714,0,0,1,.569.8,1.636,1.636,0,0,1-.317,1.059,1.865,1.865,0,0,1-1.152.531,24.752,24.752,0,0,1-4,.21,24.35,24.35,0,0,1-3.73-.168C.6,51.119.04,50.55,0,49.683q0-.739,1.427-.933a4.122,4.122,0,0,0,.9-.205,1.424,1.424,0,0,0,.813-1.487V26.636a1.531,1.531,0,0,0-1.568-1.723c-.887-.047-1.366-.2-1.45-.4A1.986,1.986,0,0,1,0,23.745a1.352,1.352,0,0,1,.313-.872,1.865,1.865,0,0,1,1.133-.531,26.823,26.823,0,0,1,3.968-.191,24.632,24.632,0,0,1,3.73.168q1.679.4,1.721,1.721c0,.5-.49.807-1.469.933a3.161,3.161,0,0,0-.876.168A1.449,1.449,0,0,0,7.7,26.65v8.635H25.642V26.636a1.534,1.534,0,0,0-1.571-1.7,2.4,2.4,0,0,1-1.31-.359.933.933,0,0,1-.242-.68,1.4,1.4,0,0,1,.191-.739,1.256,1.256,0,0,1,.606-.494c.28-.117.55-.214.807-.3a5.348,5.348,0,0,1,1.119-.154c.714-.042,1.315-.065,1.8-.065h.8a14.312,14.312,0,0,1,3.874.317c1.1.392,1.655.933,1.655,1.571a.644.644,0,0,1-.4.639,3.763,3.763,0,0,1-1.045.252,3.3,3.3,0,0,0-.881.177,1.511,1.511,0,0,0-.813,1.529V47.059a1.571,1.571,0,0,0,.49,1.31,2.276,2.276,0,0,0,1.194.466,6.738,6.738,0,0,1,.933.135.634.634,0,0,1,.545.68,2.084,2.084,0,0,1-.159.863,1.17,1.17,0,0,1-.555.522,5.223,5.223,0,0,1-.714.3,4.281,4.281,0,0,1-1.007.148C30.262,51.544,29.551,51.562,28.822,51.562Z" transform="translate(0.003 -20.492)" fill="#fff"/>
      <path id="Path_104813" data-name="Path 104813" d="M82.431,49.7h-1.8c-1.832,0-2.96-.28-3.394-.839a1.436,1.436,0,0,1-.3-.933.76.76,0,0,1,.42-.746,2.248,2.248,0,0,1,.933-.228,1.678,1.678,0,0,0,.933-.327,1.119,1.119,0,0,0,.419-.978V23.58q0-1.911-1.506-2.056l-.4-.042-.335-.028-.261-.042a.933.933,0,0,1-.233-.074.718.718,0,0,1-.135-.107c-.1-.079-.148-.284-.148-.606a1.4,1.4,0,0,1,.644-1,4.83,4.83,0,0,1,3.161-.965,6.422,6.422,0,0,1,2.471.252,1.543,1.543,0,0,1,.718,1.3V45.7c0,.813.466,1.24,1.352,1.287s1.352.41,1.352,1.082a1.336,1.336,0,0,1-1.256,1.4,12.994,12.994,0,0,1-2.637.233Z" transform="translate(-40.906 -18.629)" fill="#fff"/>
      <path id="Path_104814" data-name="Path 104814" d="M105.179,38.39l-1.277.024a3.184,3.184,0,0,1-1.133-.148.591.591,0,0,1-.355-.615,1.175,1.175,0,0,1,.406-.863,2.979,2.979,0,0,1,.933-.6,4.338,4.338,0,0,0,1.73-.993,11.48,11.48,0,0,0,.849-1.79q1.4-3.455,2.331-3.455c.321,0,.508.345.555,1.034s.079,1.217.084,1.58,0,.9,0,1.625v1.5h4.989a2.024,2.024,0,0,1,1.235.369,1.147,1.147,0,0,1,.5.974,1.082,1.082,0,0,1-.494.933,2.121,2.121,0,0,1-1.226.335h-5.024V49.025q0,4.546,2.284,4.546a6.846,6.846,0,0,0,1.865-.392,7.283,7.283,0,0,1,1.614-.387c.569,0,.858.331.858.984q0,.779-2.024,1.551a11.462,11.462,0,0,1-4.145.774,3.884,3.884,0,0,1-3.333-1.655,7.234,7.234,0,0,1-1.2-4.354Z" transform="translate(-54.659 -24.665)" fill="#fff"/>
    </g>
    <g id="hlthera" transform="translate(702.085 237.732)">
      <path id="Path_94" data-name="Path 94" d="M2685.922,329.88c.205-.458.3-1.34.506-1.34.268,0,.625,3.484,1.071,3.484.537,0,1.251-3.305,1.608-3.305.268,0,.357,1.429.715,1.429.268,0,.447-1.429.715-1.429.178,0,.356.8.625.8h.053l.061,0h-.022l.059,0-.037,0,.041,0h0l.077,0h.021l.189-.465h0l-.047-.008h0l-.014,0h0l-.056-.014h0a.645.645,0,0,1-.417-.324c-.179-.357-.357-.715-.536-.715-.358,0-.536,1.251-.714,1.251-.268,0-.179-1.429-.625-1.429s-1.34,3.306-1.608,3.306c-.357,0-.536-3.752-1.072-3.752-.3,0-.625,1.34-.893,2.144-.063.189-.088.268-.357.268h-3.419l.333.536h3C2685.8,330.327,2685.8,330.148,2685.922,329.88Z" transform="translate(-2666.25 -314.783)" fill="none"/>
      <path id="Path_111" data-name="Path 111" d="M2761.332,387.81v19.533h5.449V386.47A35.365,35.365,0,0,1,2761.332,387.81Z" transform="translate(-2734.322 -369.603)" fill="#fff"/>
      <path id="Path_96" data-name="Path 96" d="M2747.059,342.311h0Z" transform="translate(-2722.096 -327.57)" fill="#fff"/>
      <path id="Path_97" data-name="Path 97" d="M2749.36,339.05Z" transform="translate(-2724.065 -324.777)" fill="#fff"/>
      <g id="Group_45" data-name="Group 45" transform="translate(0 14.134)">
        <path id="Union_1" data-name="Union 1" d="M.46,21.227a10.382,10.382,0,0,1-.386-4.336q.031-.279.077-.546a7.919,7.919,0,0,1,1.269-3.33,10.061,10.061,0,0,1,.88-1.03,11.437,11.437,0,0,1,4.846-2.94,31.571,31.571,0,0,1,6.511-1.218c4.511-.85,13.354-.581,18.847-1.968a11.846,11.846,0,0,0,2.992-1.3c.956-.718,1.39-1.475,1.287-2.126C36.559,1.477,35.271,1.1,35.271,1.1A9.353,9.353,0,0,0,32.447.734C30.843.768,27.034.55,27.034.55H25.246L25.43.144h1.715l5.3-.1h.012V0l.179,0c4.545-.035,5.921,2.267,4.37,4.339A5.27,5.27,0,0,1,35.8,5.524c-2.094,1.526-6.918,2.228-6.918,2.228l-1.849.279L16.741,9.273s-3.024.384-3.241.468-5.38,1.37-5.38,1.37-5.594,1.514-5.358,6.935c-.017,0-.02,3.334,2.532,4.484a6.233,6.233,0,0,0,2.781.28l.408-.005.071-.012a.065.065,0,0,0,0,.011l3.14-.039v3.488H8.554v.015s-.236.026-.63.026C6.279,26.293,1.885,25.847.46,21.227Z" transform="translate(0 0)" fill="#fff"/>
      </g>
      <g id="Group_42" data-name="Group 42" transform="translate(14.992 10.25)">
        <path id="Path_104818" data-name="Path 104818" d="M169.258,32.38a.191.191,0,1,0,0,.382h.755c-.089-.126-.168-.256-.247-.382Z" transform="translate(-169.055 -27.536)" fill="#fff"/>
        <path id="Path_104819" data-name="Path 104819" d="M174.229,24.509a.191.191,0,0,1,.2-.131.186.186,0,0,1,.173.148l.872,3.73.85-1.983.549-1.281a.182.182,0,0,1,.177-.117h0a.191.191,0,0,1,.177.117l.536,1.338.494-1.166a.191.191,0,0,1,.159-.117.177.177,0,0,1,.177.084l.578.881h.387a3.766,3.766,0,0,0,.163-.466c.7-2.723-2.466-5.072-5.217-2.331-2.75-2.75-5.916-.4-5.212,2.331a4.691,4.691,0,0,0,.573,1.269h3.6Z" transform="translate(-169.121 -21.987)" fill="#fff"/>
        <path id="Path_104820" data-name="Path 104820" d="M179.917,30.08l-.466-.665-.541,1.256a.191.191,0,0,1-.355,0l-.5-1.33-1.445,3.394a.191.191,0,0,1-.177.117h0a.2.2,0,0,1-.172-.148l-.863-3.694-.606,1.828a.2.2,0,0,1-.182.131h-3.5a19.724,19.724,0,0,0,4.392,4.079s3.613-2.415,4.872-4.876h-.312a.2.2,0,0,1-.145-.092Z" transform="translate(-170.147 -25.734)" fill="#fff"/>
        <path id="Path_150" data-name="Path 150" d="M-5.055,0h1.807V.434l-2-.017Z" transform="translate(15.48 4.018)" fill="#fff"/>
      </g>
      <path id="Path_101" data-name="Path 101" d="M2749.39,339.056Z" transform="translate(-2724.094 -324.783)" fill="#fff"/>
      <path id="Path_102" data-name="Path 102" d="M2747.348,342.321h.021l.038,0Z" transform="translate(-2722.345 -327.579)" fill="#fff"/>
      <path id="Path_103" data-name="Path 103" d="M2749.481,339.081h0Z" transform="translate(-2724.171 -324.805)" fill="#fff"/>
      <path id="Path_104" data-name="Path 104" d="M2766.778,338.27v.7q-.218,0-.447.01c-1.638.06-3.566.012-5-.043v-.585Z" transform="translate(-2734.319 -324.11)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1"/>
      <path id="Path_108" data-name="Path 108" d="M2747.9,342.345l-.074,0Z" transform="translate(-2722.749 -327.597)" fill="#fff" opacity="0.21"/>
      <path id="Path_109" data-name="Path 109" d="M2746.9,342.306h0Z" transform="translate(-2721.937 -327.566)" fill="#fff" opacity="0.21"/>
      <g id="Group_41" data-name="Group 41" transform="translate(4.413)">
        <g id="Group_39" data-name="Group 39">
          <g id="Group_38" data-name="Group 38">
            <g id="Group_36" data-name="Group 36" transform="translate(3.663)">
              <rect id="Rectangle_10" data-name="Rectangle 10" width="24.384" height="2.859" fill="#fff"/>
            </g>
            <g id="Group_37" data-name="Group 37" transform="translate(0 5.539)">
              <path id="Path_105" data-name="Path 105" d="M2635.757,277.924h-31.8v.893h3.662v1.965H2632v-1.965h3.753Z" transform="translate(-2603.958 -277.924)" fill="#fff"/>
            </g>
          </g>
        </g>
        <path id="Path_107" data-name="Path 107" d="M2653.846,430.216v-1.965h-6.789V416.012h-10.718v12.238h-6.878v2.859h9.736V418.871h5v12.238h13.4v-.893Z" transform="translate(-2625.799 -390.689)" fill="#fff"/>
        <g id="Group_40" data-name="Group 40" transform="translate(22.598 8.352)">
          <path id="Path_110" data-name="Path 110" d="M2766.778,297.829h-4.231c-.861,0-1.219,1.2-1.219,1.808v8.686a45.271,45.271,0,0,0,5.449-.712Z" transform="translate(-2761.329 -297.829)" fill="#fff"/>
        </g>
        <path id="Path_106" data-name="Path 106" d="M2634.911,297.829h-5.45v29.39h4.231c.861,0,1.219-1.429,1.219-2.157Z" transform="translate(-2625.799 -289.479)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>
`;
