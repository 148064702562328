import { Component, EventEmitter, Output } from '@angular/core';
import { scrollDown } from '../../utiltes/functions';

@Component({
  selector: 'hlthera-scroll-button',
  templateUrl: './scroll-button.component.html',
  styleUrl: './scroll-button.component.scss',
})
export class ScrollButtonComponent {
  @Output() scroll: EventEmitter<void> = new EventEmitter<void>();

  onScrollClick(): void {
    this.scroll.emit();

    scrollDown(400);
  }
}
