import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[classCheck]',
})
export class ClassCheckDirective implements OnInit, OnDestroy {
  @Input() classToCheck: string = '';
  @Input() elementToHide: HTMLElement | null = null;
  private observer: MutationObserver;
  private isHidden: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.observer = new MutationObserver(() => this.checkClass());
  }

  ngOnInit() {
    this.checkClass();
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  private checkClass() {
    if (
      !this.isHidden &&
      this.el.nativeElement.classList.contains(this.classToCheck)
    ) {
      this.hideElement();
    }
  }

  private hideElement() {
    if (this.elementToHide) {
      this.renderer.setStyle(this.elementToHide, 'display', 'none');
      this.isHidden = true; // Mark as hidden
      this.observer.disconnect(); // Stop observing
    }
  }

  private showElement() {
    if (this.elementToHide) {
      this.renderer.removeStyle(this.elementToHide, 'display');
      this.isHidden = false; // Mark as not hidden
    }
  }
}
